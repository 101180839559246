import { PUBLIC_MIXPANEL_TOKEN } from '$env/static/public'
import mixpanel from 'mixpanel-browser'

export function initMixpanel() {
  mixpanel.init(PUBLIC_MIXPANEL_TOKEN, {
    api_host: 'https://api-eu.mixpanel.com',
    track_pageview: false,
    persistence: 'localStorage',
    opt_out_tracking_by_default: true,
  })
}

import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55')
];

export const server_loads = [0,3,10];

export const dictionary = {
		"/.well-known/[...catchAll]": [14],
		"/api/[...catchAll]": [54],
		"/(auth)/auth": [12,[2]],
		"/(auth)/auth/signout": [13,[2]],
		"/callback/[...catchAll]": [55],
		"/[lang]/(default)": [~22,[3,10],[,11]],
		"/[lang]/(default)/about": [~24,[3,10],[,11]],
		"/[lang]/(default)/app": [~25,[3,10],[,11]],
		"/[lang]/(default)/become-a-partner": [~26,[3,10],[,11]],
		"/[lang]/(default)/become-a-partner/confirmation": [27,[3,10],[,11]],
		"/[lang]/(default)/careers": [~28,[3,10],[,11]],
		"/[lang]/(default)/careers/[id]/[title]": [~29,[3,10],[,11]],
		"/[lang]/(auth)/checkout": [~15,[3,4],[,,5]],
		"/[lang]/(default)/classes/[id]": [~30,[3,10],[,11]],
		"/[lang]/(default)/classes/[id]/[title]": [~31,[3,10],[,11]],
		"/[lang]/(auth)/confirmation": [~16,[3,4]],
		"/[lang]/(default)/credits": [~32,[3,10],[,11]],
		"/[lang]/(default)/discover/[city]": [~33,[3,10],[,11]],
		"/[lang]/(default)/discover/[city]/all/[category]": [~36,[3,10],[,11]],
		"/[lang]/(default)/discover/[city]/[vicinity]": [~34,[3,10],[,11]],
		"/[lang]/(default)/discover/[city]/[vicinity]/[category]": [~35,[3,10],[,11]],
		"/[lang]/(auth)/email-verification": [~17,[3,4],[,,6]],
		"/[lang]/(default)/faq": [~37,[3,10],[,11]],
		"/[lang]/(default)/follow-us": [~38,[3,10],[,11]],
		"/[lang]/(default)/for-business": [39,[3,10],[,11]],
		"/[lang]/(default)/free-trial": [~40,[3,10],[,11]],
		"/[lang]/(default)/friend/request/[token]": [~41,[3,10],[,11]],
		"/[lang]/(default)/friskvard": [42,[3,10],[,11]],
		"/[lang]/(default)/get-started": [~43,[3,10],[,11]],
		"/[lang]/(default)/gift-card": [~44,[3,10],[,11]],
		"/[lang]/(auth)/login": [~18,[3,4],[,,7]],
		"/[lang]/(default)/membership": [~45,[3,10],[,11]],
		"/[lang]/(default)/occupational-health": [~46,[3,10],[,11]],
		"/[lang]/(default)/occupational-health/confirmation": [47,[3,10],[,11]],
		"/[lang]/(auth)/password/reset": [~19,[3,4],[,,8]],
		"/[lang]/(default)/privacy": [~48,[3,10],[,11]],
		"/[lang]/(auth)/signup": [~20,[3,4],[,,9]],
		"/[lang]/(default)/studios": [~49,[3,10],[,11]],
		"/[lang]/(default)/studios/[cityOrStudioId]": [~50,[3,10],[,11]],
		"/[lang]/(default)/studios/[cityOrStudioId]/[name]": [~51,[3,10],[,11]],
		"/[lang]/(default)/terms": [~52,[3,10],[,11]],
		"/[lang]/(default)/trial": [~53,[3,10],[,11]],
		"/[lang]/(auth)/verify": [21,[3,4]],
		"/[lang]/(default)/[...catchAll]": [23,[3,10],[,11]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';